import { UX2 } from '@wsb/guac-widget-core';
import React from 'react';
import SubscribeForm from '../../form-components/subscribe-form';
import DataAid from '../../constants/dataAids';
import FormSuccessMessage from '../../form-components/form-success-message';
import InnerForm from '../../form-components/inner-form';

class SubscribeForm2 extends SubscribeForm {
  render() {
    const {
      confirmationMessage,
      isInternalPage,
      order,
      section,
      sectionTitle,
      staticContent,
      category,
      couponConfirmationMessage,
      couponDiscount,
      couponDiscountMessage
    } = this.props;

    const { verificationText } = staticContent;
    const { Block } = UX2.Element;

    if (this.state.formSubmitted) {
      return (
        <Block category={ category } section={ section }>
          <FormSuccessMessage
            formSuccessMessage={
              this.shouldShowCoupon() ? couponConfirmationMessage : confirmationMessage
            }
            verificationText={ verificationText }
            olsConfigHost={ this.getOlsConfigHost() }
            showCoupon={ this.shouldShowCoupon() }
            couponDiscount={ couponDiscount }
            couponDiscountMessage={ couponDiscountMessage }
          />
        </Block>
      );
    }

    const styles = {
      container: {
        'display': 'block',
        '@md': {
          display: 'flex',
          alignItems: 'center'
        }
      },
      title: {
        'marginBottom': 'small',
        '@md': {
          marginRight: 'medium',
          marginBottom: '0'
        }
      }
    };
    return (
      <React.Fragment>
        <Block category={ category } section={ section } style={ styles.container }>
          <Block style={ styles.title }>
            { this.renderSectionTitle(sectionTitle, isInternalPage, order) }
          </Block>
          <Block style={{ flexGrow: '1' }} data-aid={ DataAid.SUBSCRIBE_INNER_FORM_REND }>
            <InnerForm
              formSubmitHost={ this.getFormSubmitHost() }
              onSubmit={ this.onSubmit }
              { ...this.props }
            />
          </Block>
        </Block>
        <UX2.Component.Grid inset style={{ paddingTop: 'large' }}>
          { this.shouldShowCoupon() &&
            this.renderDescriptionSection({ forceCouponDescription: true }) }
        </UX2.Component.Grid>
      </React.Fragment>
    );
  }
}

export default SubscribeForm2;
